
export default {
  props: ['isVideo', 'album'],
  methods: {
    goToAlbum() {
      this.$router.push(`/media/${this.album.id}`);
    },
  },
  computed: {
    isDark() {
      return this.$q.dark.isActive;
    },
    date() {
      let date = new Date(this.album.createdAt);

      return `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
    },
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return this.$i18n.locale == 'ar';
    },
  },
};
